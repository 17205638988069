import React from "react";
import Header from "../../Components/Header/Header";
import RoundedProfilePicture from "../../Components/RoundedProfilePicture/RoundedProfilePicture";
import { connect } from "react-redux";
import {
  ExitIcon,
  InfoIcon,
  ReferIcon,
  VolunteerIcon,
} from "../../Assets/assets";
import { Route, Routes } from "react-router-dom";
import LanguageModal from "../../Components/LanguageModal/LanguageModal";
import LogoutModal from "../../Components/LogoutModal/LogoutModal";
import OptOutModal from "../../Components/OptOutModal/OptOutModal";
import { updateOpting } from "../../Redux/volunteer/action";
import OptInModal from "../../Components/OptInModal/OptInModal";

function Profile(props) {
  const getGenderFullForm = (value) => {
    switch (value) {
      case "M":
        return "MALE";

      case "F":
        return "FEMALE";

      default:
        return value;
    }
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob.year, dob.month - 1, dob.date); // month is 0-based

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    // Adjust age if the birth month hasn't occurred yet this year, or if it's the birth month but the day hasn't occurred yet.
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  };

  const userDetails = [
    {
      title: props.languageData?.fullName,
      value: props.profile.data?.aadhaar?.fullName,
    },
    {
      title: props.languageData?.gender,
      value: getGenderFullForm(props.profile.data?.aadhaar?.gender),
    },
    {
      title: props.languageData?.age,
      value: calculateAge(props.profile.data?.aadhaar?.dob),
    },
    {
      title: props.languageData?.phoneNumber,
      value: props.profile.data?.phoneNumber,
    },
    {
      title: props.languageData?.address,
      value: props.profile.data?.aadhaar?.address?.fullAddress,
    },
    {
      title: props.languageData?.aadhaarNumber,
      value: props.profile.data?.aadhaar?.aadhaarNumber,
    },
  ];

  const options = [
    ...(props.profile.data?.volunteering?.currentStatus === "OPTEDIN"
      ? [
          {
            title: props.languageData.volunteeringStatus,
            status: props.languageData.optIn.toUpperCase(),
            icon: <VolunteerIcon width={20} height={20} />,
            dataCy: "volunteer-status-optIn",
            onClick: () => {
              props.navigate("/profile/optOut");
            },
          },
        ]
      : []),

    ...(props.profile.data?.volunteering?.currentStatus === "OPTEDOUT"
      ? [
          {
            title: props.languageData.volunteeringStatus,
            status: props.languageData.optOutStatus.toUpperCase(),
            icon: <VolunteerIcon width={20} height={20} />,
            dataCy: "volunteer-status-optOut",
            onClick: () => {
              props.navigate("/profile/optIn");
            },
          },
        ]
      : []),

    {
      title: props.languageData.referFriend,
      icon: <ReferIcon width={20} height={20} color="black" />,
      dataCy: "referFriend-button",
      onClick: () => {
        props.navigate("/referFriend");
      },
    },
    {
      title: props.languageData.logout,
      icon: <ExitIcon width={20} height={20} color="#E11023" />,
      dataCy: "logout-button",
      onClick: () => {
        props.navigate("/profile/logout");
      },
    },
  ];

  return (
    <>
      <Header
        languageData={props.languageData}
        title={props.languageData?.profile}
        backOnClick={() => {
          props.navigate("/");
        }}
        languageOnClick={() => {
          props.navigate("/profile/languages");
        }}
      />
      <div className="remaining-body-height background-white-80-percentage flex-justify-content-center font-family-RHD-medium overflow-scroll">
        <div className=" inherit-parent-height padding-large max-width-500px">
          <div className=" flex-justify-content-center padding-top-default padding-bottom-larger">
            <RoundedProfilePicture
              size="large"
              src={props.profile.data?.aadhaar?.pictureSrc || null}
            />
          </div>

          <div className=" display-flex flex-direction-column">
            {userDetails.map((data, index) => (
              <div
                key={index}
                className="padding-vertical-default inherit-parent-width display-flex"
              >
                <div
                  className=" font-size-small display-flex flex-align-items-center font-family-RHD-medium font-color-tertiary"
                  style={{ width: "150px", minWidth: "150px" }}
                >
                  {data.title}
                </div>
                <div className=" font-size-default ">{data.value}</div>
              </div>
            ))}
          </div>
          <div className="padding-top-default padding-bottom-large font-size-small display-flex flex-align-items-center font-color-tertiary">
            <div className=" padding-right-default flex-justify-content-center">
              <InfoIcon color="#404040" />{" "}
            </div>{" "}
            {props.languageData.infoFetchedFromAadhaar}
          </div>
          <div style={{ borderBottom: "1px solid #F2F2F2" }} />
          {options.map((data, index) => (
            <div
              key={index}
              className=" font-size-medium display-flex flex-align-items-center padding-vertical-default margin-vertical-default flex-justify-content-space-between "
              data-cy={data.dataCy}
              onClick={() => {
                data.onClick();
              }}
            >
              <div className=" display-flex flex-align-items-center">
                {data.icon}

                <div
                  className={`padding-left-default ${
                    data.dataCy === "logout-button" ? "font-color-red" : ""
                  }`}
                >
                  {data.title}
                </div>
              </div>

              {data.status && (
                <div className=" background-color-grey padding-right-default padding-left-default padding-top-small padding-bottom-small border-radius-default font-color-tertiary">
                  {data.status}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Routes>
        <Route
          path="languages"
          element={
            <LanguageModal
              languageData={props.languageData}
              languageList={props.languageList}
              navigate={props.navigate}
              changeLanguage={props.changeLanguage}
              profile={props.profile}
            />
          }
        />
        <Route
          path="logout"
          element={
            <LogoutModal
              languageData={props.languageData}
              navigate={props.navigate}
              profile={props.profile}
              logout={props.logout}
            />
          }
        />
        <Route
          path="optOut"
          element={
            <OptOutModal
              languageData={props.languageData}
              navigate={props.navigate}
              updateOpting={props.updateOpting}
            />
          }
        />
        <Route
          path="optIn"
          element={
            <OptInModal
              languageData={props.languageData}
              navigate={props.navigate}
              updateOpting={props.updateOpting}
              showIcon={true}
            />
          }
        />
      </Routes>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
  };
};

const mapDispatchToProps = function () {
  return {
    updateOpting: (optingStatus) => updateOpting(optingStatus),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
