import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module ProfileSaga
 */

/**
 *
 * @param {profiles} payload profile data
 */
export function setAccountProfiles(profiles) {
  store.dispatch({
    type: actionTypes.SET_ACCOUNT_PROFILES,
    payload: {
      profiles: profiles,
    },
  });
}

export function setReferrals(referrals) {
  store.dispatch({
    type: actionTypes.SET_ACCOUNT_REFERRALS,
    payload: {
      referrals: referrals,
    },
  });
}

export function uploadResume(profileId, file, navigate) {
  store.dispatch({
    type: actionTypes.UPLOAD_RESUME,
    payload: {
      profileId: profileId,
      file: file,
      navigate: navigate,
    },
  });
}

export function changeLanguage(language) {
  store.dispatch({
    type: actionTypes.CHANGE_LANGUAGE,
    payload: {
      language: language,
    },
  });
}
