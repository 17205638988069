import { all, put, takeEvery } from "redux-saga/effects";
import { setErrorStatus } from "../status/action";
import { getFileUrl, uploadResumesToStorage } from "../../Services/storage";
import { isValidObject } from "../../Services/validators";
import { getFileExtension } from "../../Utils/constants";
import { updateFileURLToCurrentUser } from "../../Services/database";

export const actionTypes = {
  SET_ACCOUNT_PROFILES: "SET_ACCOUNT_PROFILES",
  SET_ACCOUNT_REFERRALS: "SET_ACCOUNT_REFERRALS",
  CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
  UPLOAD_RESUME: "UPLOAD_RESUME",
};

function* setProfilesWorker(action) {
  try {
    yield setProfileLoading(true);

    if (!isValidObject(action.payload?.profiles)) {
      yield put({
        type: "SET_PROFILES",
        payload: {},
      });
      yield setProfileLoading(false);
      return;
    }

    const pictureSrc = yield getFileUrl(
      action.payload?.profiles?.aadhaar?.picture
    );

    yield put({
      type: "SET_PROFILES",
      payload: {
        ...action.payload.profiles,
        aadhaar: {
          ...action.payload.profiles.aadhaar,
          pictureSrc: pictureSrc?.url || null,
        },
      },
    });

    if (action.payload?.profiles?.resumeUrl) {
      const downloadURL = yield new Promise((resolve, reject) => {
        getFileUrl(action.payload?.profiles?.resumeUrl)
          .then(resolve)
          .catch(reject);
      });

      yield put({
        type: "SET_RESUME_DOWNLOAD_URL",
        payload: {
          url: downloadURL.url,
        },
      });
    }

    yield setProfileLoading(false);
  } catch (error) {
    yield setProfileLoading(false);
    setErrorStatus(error);
  }
}

function* setReferralsWorker(action) {
  try {
    yield setProfileLoading(true);

    if (isValidObject(action.payload.referrals)) {
      let grandTotal = 0;
      for (let i = 0; i < Object.values(action.payload.referrals).length; i++) {
        grandTotal += Object.values(action.payload.referrals)?.[i].totalCount;
      }
      yield put({
        type: "SET_REFERRALS",
        payload: {
          referrals: { ...action.payload.referrals, grandTotal: grandTotal },
        },
      });
    }
    yield setProfileLoading(false);
  } catch (error) {
    yield setProfileLoading(false);
    setErrorStatus(error);
  }
}

function* changeLanguageWorker(action) {
  try {
    yield put({
      type: "SET_LANGUAGE",
      payload: {
        language: action.payload.language,
      },
    });
  } catch (error) {
    setErrorStatus(error);
  }
}
function* uploadResumeWorker(action) {
  try {
    setProfileLoading(true);
    const extension = getFileExtension(action.payload.file);
    const URL = yield new Promise((resolve, reject) => {
      uploadResumesToStorage(
        action.payload.file,
        `${action.payload.profileId}.${extension}`
      )
        .then(resolve)
        .catch(reject);
    });

    yield new Promise((resolve, reject) => {
      updateFileURLToCurrentUser(action.payload.profileId, URL)
        .then(resolve)
        .catch(reject);
    });

    const downloadURL = yield new Promise((resolve, reject) => {
      getFileUrl(URL).then(resolve).catch(reject);
    });

    yield put({
      type: "SET_RESUME_DOWNLOAD_URL",
      payload: {
        url: downloadURL.url,
      },
    });

    yield action.payload.navigate("/success");
    setProfileLoading(false);
  } catch (error) {
    setProfileLoading(false);
    setErrorStatus(error);
  }
}

export default function* profileWatcher() {
  yield all([
    takeEvery("SET_ACCOUNT_PROFILES", setProfilesWorker),
    takeEvery("SET_ACCOUNT_REFERRALS", setReferralsWorker),
    takeEvery("CHANGE_LANGUAGE", changeLanguageWorker),
    takeEvery("UPLOAD_RESUME", uploadResumeWorker),
  ]);
}

function* setProfileLoading(bool) {
  yield put({
    type: "SET_PROFILE_LOADING",
    payload: {
      loading: bool,
    },
  });
}
