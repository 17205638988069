import React from "react";
import Modal from "../Modal/Modal";

export default function SuccessModal(props) {
  return (
    <Modal
      show={true}
      canIgnore={true}
      onClose={() => {
        props.navigate(-1);
      }}
      width="inherit-parent-width"
      position=" absolute-center-self-horizontally bottom-0 "
      maxWidth="max-width-630px"
      background="false"
      boxShadow="false"
      borderRadius="false"
      height="height-fit-to-content"
    >
      <div
        className="font-color-secondary font-family-gilroy-medium font-color-secondary text-align-center padding-larger background-white"
        data-cy={"optIn-confirmation-modal"}
      >
        {props.showIcon ? <SuccessFallBack /> : <></>}
        <div className=" font-family-RHD-medium font-size-medium font-size-large">
          {props.languageData?.resumeUploaded.toUpperCase()}
        </div>
        <div className=" padding-top-default font-size-small">
          {props.languageData?.resumeUploadedDescription}
        </div>

        <div className="display-flex padding-top-larger inherit-parent-width ">
          <div
            style={{
              width: "100%",
              height: "40px",
              borderRadius: "2px",
              boxShadow: "0px 0px 6px 6px white",
              border: "solid 1px #0000003D",
            }}
            onClick={() => {
              props.navigate(-1);
            }}
            className="background-color-white flex-justify-content-center flex-align-items-center font-size-small"
          >
            {props.languageData?.continue}
          </div>
        </div>
      </div>
    </Modal>
  );
}

const SuccessFallBack = () => {
  return (
    <svg
      width="106"
      height="106"
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6"
        y="6"
        width="94"
        height="94"
        rx="47"
        stroke="url(#paint0_linear_914_268)"
        stroke-opacity="0.03"
        stroke-width="12"
      />
      <g opacity="0.8">
        <path
          d="M53 28C46.3705 28 40.0098 30.6346 35.3218 35.3218C30.6343 40.0094 28 46.3698 28 53C28 59.6302 30.6346 65.9902 35.3218 70.6782C40.0094 75.3657 46.3698 78 53 78C59.6302 78 65.9902 75.3654 70.6782 70.6782C75.3657 65.9906 78 59.6302 78 53C77.9928 46.3723 75.3582 40.0166 70.6708 35.3292C65.9833 30.6417 59.6277 28.0074 53 28ZM64.6154 47.8726L51.7957 60.6923C51.0433 61.4423 49.827 61.4423 49.0769 60.6923L41.3846 53C40.9471 52.2284 41.0913 51.2596 41.7356 50.6514C42.3822 50.0433 43.3582 49.9543 44.1034 50.4351L50.4351 56.6923L61.8966 45.3077C62.6418 44.8245 63.6178 44.9135 64.2644 45.5217C64.9087 46.1298 65.0529 47.101 64.6154 47.8726Z"
          fill="url(#paint1_linear_914_268)"
        />
        <path
          d="M53.0089 20H53C44.2488 20 35.8536 23.4774 29.6654 29.6645L29.665 29.665C23.4769 35.8531 20 44.2485 20 53C20 61.7519 23.4774 70.1465 29.6645 76.3346L29.665 76.335C35.8531 82.5231 44.2485 86 53 86C61.7519 86 70.1465 82.5226 76.3346 76.3355L76.335 76.335C82.5231 70.1469 86 61.7515 86 53L86 52.9913C85.9905 44.2463 82.5142 35.8589 76.3276 29.6724C70.1408 23.4856 61.7535 20.0097 53.0089 20Z"
          stroke="url(#paint2_linear_914_268)"
          stroke-opacity="0.08"
          stroke-width="16"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_914_268"
          x1="53"
          y1="12"
          x2="53"
          y2="94"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-opacity="0.6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_914_268"
          x1="53"
          y1="28"
          x2="53"
          y2="78"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-opacity="0.9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_914_268"
          x1="53"
          y1="28"
          x2="53"
          y2="78"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-opacity="0.6" />
        </linearGradient>
      </defs>
    </svg>
  );
};
